/** Mobile */
.simple-banner__mobile-links {
	@media #{$bs-mobile}{ 
		position: absolute;
		bottom: 10px;
		left: 10px;
		right: 10px;
		// padding: 4px calc(#{$spacing-unit});
		border-radius: 4px;
		background: rgba($color-black, 0.6);
		overflow: hidden;
		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 12px;
			font-size: 13px;
			text-transform: uppercase;
			font-weight: 700;
			color: $color-switch;
			letter-spacing: 0.9px;
			&:hover {
				text-decoration: none;
				background: rgba($color-black, 0.4);
			}
			&:focus {
				outline: 0;
			}
			.quick-link-entry-mobile-left {
				display: flex;
				align-items: center;
				padding-right: 12px;
			}
		}
		figure {
			margin-bottom: 0;
		}
		.simple-banner__mobile-links__icon {
			// float: left;
			width: 20px;
			flex: 0 0 auto;
			margin-right: 15px;
			img {
				width: 100%;
			}
		}
		.simple-banner__mobile-links__chev {
			// float: right;
			transform: rotate(-90deg);
		}
	}	
	@media #{$medium-up}{ 
		display: none;
	}
}

/** Desktop */
.desktop-quick-links {
	@media #{$bs-mobile} {
		display: none;
		& + .container {
			display: none;
		}
	}
	@media #{$medium-up} {
		margin-top: 80px;
		margin-bottom: 64px;
		// .container {
		// 	padding-bottom: 32px;
		
		// 	border-bottom: 1px solid $color-almostwhitegrey;
		// }
		.quick-link-item.animate-when-visible {
			opacity: 0;
		}
		.quick-link-item-body-img-container-outer {
			margin-right: 12px;
			flex: 0 0 auto;
			// text-align: center;
			@media #{$extra-large-up} {
				margin-right: 32px;
			}
		}
		.quick-link-item-body-img-container {
			background-color: $color-primary;
			display: inline-block;
			padding: 12px;
			border-radius: 50%;
			@media #{$large-up} {
				padding: 16px;
			}
			@media #{$extra-large-up} {
				padding: 24px;
			}
			.quick-link-item-body-img-container-inner {
				width: 24px;
				height: 24px;
				display: block;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;

				transition: all 0.3s;
				@media #{$large-up} {
					width: 32px;
					height: 32px;
				}
				@media #{$extra-large-up} {
					width: 40px;
					height: 40px;
				}
			}
		}
		.quick-link-item-body {
			// display: flex;
			padding: 16px;
			text-align: center;
			@media #{$large-up} {
				display: flex;
				padding: 24px 16px;
				text-align: left;
			}
			@media #{$extra-large-up} {
				padding: 32px 16px;
			}
		}
		.quick-link-item-body-summary {
			margin-top: 12px;
			color: $font-color;
			flex-basis: 100%;
			&:hover, &:focus {
				color: $font-color;
			}
			@media #{$large-up} {
				margin-top: 0;
			}
		}
		.quick-link:hover {
			.quick-link-item-body-img-container-inner {
				transform: scale(1.1);
			}
		}
		& + .container {
			.dividing-line {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}