%body-style {
	font-size: $default-font-size-mobile-standard;
	line-height: $default-line-height-mobile-standard;
	color: $color-midgrey;
	.font-large {
		font-size: $default-font-size-mobile-large;
		line-height: $default-line-height-mobile-large;
	}
	@media #{$large-up} {
		font-size: $default-font-size-desktop-standard;
		line-height: $default-line-height-desktop-standard;
		.font-large {
			font-size: $default-font-size-desktop-large;
			line-height: $default-line-height-desktop-large;
		}
	}
	font-family: $body-font-family;
}