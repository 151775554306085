.collage__images {
	img {
		max-width: 100%;
	}
}
.collage__images__column_left {
	position: relative;
}
.collage__images__featured {
	background-size: cover;
	height: 328px;
	@media #{$large-up} {
		position: absolute;
		top: 0;
		right: 15px;
		left: 15px;
		bottom: 0;
		height: auto;
	}
	
}
.collage__content__container {
	text-align: center;
    margin-bottom: 32px;
    @media #{$medium-up} {
    	height: 100%;
	    display: flex;
	    align-items: center;
    	text-align: left;
		max-width: 360px;
		margin-bottom: 0;
    }
    p {
    	@include Section-Subheading;
    	@media #{$medium-up} {
    		text-align: left;
    	}
    }
	& > *:last-child {
		margin-bottom: 0;
	}
}
.collage__images__subsidiary {
	
	.collage__images__subsidiary_item {
		height: 144px;
		@media #{$medium-up} {
			margin-bottom: calc(4*#{$spacing-unit});
		}
		@media #{$medium-down} {
			margin-top: 4%;
			width: 48%;
			float: left;
			&:first-child {
				margin-right: 2%;
			}
			&:nth-child(2) {
				margin-left: 2%;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
		@media #{$medium-down} {
			&:nth-child(3) {
				display: none;
			}
		}
	}
}

.matrix-block-textWithImageCollage {
	@include General-Section-Margin;
}
.collage > .row {

	// fix iOS 10 not vertically centered issue
	@media #{$medium-up} {
		align-items: center;
	}
}