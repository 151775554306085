.layout-content {
	margin-top: 32px;
	margin-bottom: 56px;
	&.padding-no-top {
	    margin-top: 0;
	}
	&.padding-no-bottom {
	    margin-bottom: 0;
	}
	&.padding-no-top-no-bottom {
	    margin-top: 0;
	    margin-bottom: 0;
	}
	@media #{$medium-only} {
		margin-top: 48px;
		margin-bottom: 48px;
	}
	@media #{$large-up} {
		margin-top: 64px;
		margin-bottom: 64px;
	}
}
.page-title {
	margin-top: 0;
	margin-bottom: 8px;
	@media #{$large-up} {
		margin-bottom: 20px;
	}
}
.page-summary {
	@include Section-Subheading;
	text-align: left;
	
	margin-bottom: 35px;
	@media #{$large-up} {
		margin-bottom: 38px;
	}
}
.page-summary, .body-intro {
	p {
		margin-bottom: 12px;
	}
}
.general-layout-page {
	.page-title, .page-summary {
		@media #{$large-up} {
			width: 65.8%;
		}
	}
}
.general-sub-page-list {
	margin-top: 48px;
}
.general-sub-page-list + * {
	margin-top: 21px;
}
.container {
	.reusable-snippet-sections, &.reusable-snippet-sections {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

// /////////////// Sidebar layout styling /////////////////////////////

.sidebar-layout {
	position: relative;
	// display: flex;
}
.sidebar-layout-background {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 50%;
	background-color: $color-sidebar-bg;
}
.sidebar-layout-sidebar {
	background-color: $color-sidebar-bg;

	padding-top: 16px;
	@media #{$large-up} {
		padding-right: 0;
		padding-top: 64px;
		padding-bottom: 64px;
	}

	// for sidebar arrow to show up
	position: relative;
	z-index: 1;
}
.sidebar-layout-content {
	background-color: #fff;
	@media #{$large-up} {
		padding-left: 0;
	}
	.page-headings, .neo-section {
		.container {
			max-width: 100% !important;
			padding-left: 0;
			padding-right: 0;
			@media #{$large-up} {
				padding-left: 64px;
			}
		}
	}
}
.sidebar-nav {
	margin-bottom: 0;
}
@media #{$medium-down} {
	.sidebar-layout-background {
		display: none;
	}
	.sidebar-layout-sidebar {
		background-color: transparent;
	}
}
// /////////////// end of sidebar layout styling /////////////////////////////


// ///////////////////// News page layout //////////////
.news-article-page {
	.page-headings, .neo-section {
		.container {
			@media #{$large-up} {
			    max-width: 748px;
			}
			@media #{$extra-large-up} {
			    max-width: 748px;
			}
		}
	}
}
// ///////////////////// End of News page layout //////////////