.contact-intro {
	margin-bottom: 38px;
}
.contact-page-image {
	img {
		border-radius: 4px;
	}
	@media #{$medium-down} {
		margin-bottom: 10px;
	}
}
.contact-page {
	.page-summary {
		@media #{$large-up} {
			margin-bottom: 0;
		}
		// @media #{$large-only} {
		// 	font-size: 17px;
		// 	line-height: 23px;
		// }
	}
}
.contact-page-contact-details {
	.contact-details-icon {
		path {
			fill: #3d3935;
		}
	}
}

#enquiry-form {
	padding: 24px 16px 16px;
	background-color: #fafafa;

	@media #{$medium-down} {
		margin-top: calc(48px - 1rem);
	}
	.form-heading {
		font-size: 26px;
		font-weight: 600;
		line-height: 28px;
		margin-bottom: 15px;
		color: $color-tertiary;
	}
	input[type="submit"] {
		width: 100%;
	}
	.form-group {
		margin-bottom: 16px;
	}
	label {
		font-weight: bold;
		line-height: 22px;
		margin-bottom: 8px;
	}
	.form-text {
		font-size: 12px;
		line-height: 15px;
		margin-bottom: 10px;
	}
	.form-control {
		border-radius: 4px;
		font-size: 14px;
		padding: 0 16px;
		height: 48px;
	}
	textarea.form-control {
		line-height: 24px;
		padding-top: 8px;
		padding-bottom: 8px;
		height: auto;
	}
	.message-list {
		margin-top: 16px;
		.form-message {
			margin-bottom: 8px;
			font-weight: 600;
		}
	}
}
.you-tell-me {
	display: none;
}