.ie {
	#enquiry-form {
		.form-control {
			&:not(textarea) {
				height: 48px;
			}
		}
	}

	.news-list  {
		.news-list__entry {
			.card-wrap-link {
				flex: 0 0 auto;
			}
		}
	}
}