$button-bg-color: $color-primary !default;
$button-bg-color-hover: darken($button-bg-color, 10%);
$button-text-color: $color-switch;
$button-text-color-hover: $color-switch;

%button {
	@include clear-appearance;
	font-size: 13px;
  	font-weight: 900;
  	letter-spacing: 0.9px;
  	line-height: 16px;
	display: inline-block;
	background: $button-bg-color;
	color: $button-text-color;
	padding: 16px 32px;
	// border: 2px solid $button-text-color;
	border-radius: 100px;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	outline: 0;
	min-width: 264px;
	text-align: center;

	&:hover, &:focus, &:active {
		color: $button-text-color-hover;
		background: $button-bg-color-hover;
		transition-duration: 0.3s;
		text-decoration: none;
		outline: none;
		box-shadow: none;
	}

	&[disabled] {
		pointer-events: none;
	}
}

.button, form input[type='submit'] {
	@extend %button;
}

.button-small {
	@extend %button;
	border-radius: 2px;
	padding: 10px 15px;
	font-weight: 900;
	letter-spacing: 0.9px;
}
.button-alt {
	@extend %button;
	background-color: $font-color;
	border: none;
	&:hover {
		color: $color-switch;
	}
}
.fluid-button {
	width: 100%;
	font-weight: 900;
	cursor: pointer;
}

.hc-richtext-button {
	margin-bottom: 1rem;
	a {
		@extend %button;
	}
}

// .button-light {
// 	@extend %button;
// 	color: $button-bg-color;
// 	border-color: $button-bg-color;
// 	&:hover, &:focus, &:active {
// 		background: $button-bg-color;
// 		color: $button-bg-color-hover;
// 	}
// }