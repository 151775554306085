//HEADER - MAIN
.header-main {
	background-color: #ffffff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	.navbar {
		padding-left: 0;
		padding-right: 0;
	}
}
.header-main__logo-wrap {
	// transition: none;
	display: inline-flex;
	align-items: center;
	// justify-content: flex-start;
	width: 258px;
	height: 76px;
	&.logo-standard {
		
	}
	&.logo-long {

	}
	&.logo-high {

	}
}
.site-logo-canvas {
	flex: 0 0 auto;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: 0 center;
	background-size: contain;
}

.menu-link-inner {
	display: inline-block;
}

.nav-link {
	font-size: 14px;
	font-weight: bold;
	color: $font-color;
	padding-top: 1rem;
	padding-bottom: 1rem;
	.menu-link-inner {
		transition: all 0.4s;
	}
}

.nav-item-first-level {
	.nav-link {
		.menu-link-inner {
			padding: 8px 15px;
			// color: #3d3935;
			text-transform: uppercase;
			letter-spacing: 0.9px;
			font-size: 13px;
			line-height: 13px;
			padding-top: 10px;
		}
		&:hover {
			color: $desktop-menu-lvl1-hover;
		}
	}
	&.active {
		.nav-link {
			.menu-link-inner {
				color: $desktop-menu-activeandhover;
				border-radius: 16px;
				border: 2px solid $desktop-menu-activeandhover;
				font-weight: 900;
				padding-top: 8px;
			}
		}
	}
	&:hover, &:focus {
		.nav-link {
			.menu-link-inner {
				color: $desktop-menu-activeandhover;
			}
		}
	}
}

.dropdown-menu {
	padding: 0;
	background-color: $desktop-menu-lvl2-3-itembg;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
	border: none;
	line-height: 17px;
	.dropdown-item {
		// padding-top: 0.5rem;
		// padding-bottom: 0.5rem;
		padding-top: 0;
		padding-bottom: 0;
	}
}

.dropdown-item.active, .dropdown-item:active {
    background-color: $desktop-menu-lvl2-3-itembg;
}
.dropdown-item:not(:last-child) {
	border-bottom: 1px solid $desktop-menu-dropdown-divider;
}
.dropdown-item:hover {
	a {
		color: $font-color;
	}
}

@media #{$large-up} {
	.main-menu .nav-item-first-level > .nav-link > .sub-arrow {
		display: none;
	}
}

// .sticky-wrapper {
// 	transition: all 0.15s ease-out;
// }
.is-sticky.header-main {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
	width: 100%;
	.header-main__logo-wrap {
		height: 55px;
		img {
			
		}
	}
}

@media #{$medium-down} {
	.navbar-head {
		display: flex;
	}
	.navbar-brand {
		flex: 0 1 155px;
	}
	.navbar-toggler {
		flex: 0 0 auto;
		font-family: $heading-font-family;
		font-size: 14px;
		font-weight: 600;
		color: #fff;
		padding: 10px 16px;
		background-color: $mobile-menu-toggler-bg;
		border-radius: 4px;
		transition: all 0.4s;
		&:focus, &:hover {
			outline: 0;
			background-color: $mobile-menu-toggler-bg-hover;
		}
		&:active {
			background-color: $mobile-menu-toggler-bg-hover;	
		}
		&[aria-expanded="true"] {
			background-color: $mobile-menu-toggler-bg-expanded;
		}
		.navbar-toggler-text {
			margin-right: 7px;
		}
	}

	.header-main {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
		// .navbar {
		// 	padding-bottom: 0;
		// }
		.navbar-brand {
			margin-left: 15px;
		}
		.navbar-toggler {
			margin-right: 15px;
			cursor: pointer;
		}
	}

	.main-menu {
		position: absolute;
		top: 92px;
		left: 0;
		right: 0;
		z-index: 1000;
		// flex: 0 0 100%;
		
		max-height: calc(100vh - 92px - 48px);
		overflow-y: auto;

		li {
			.nav-link {
				padding: 16px;
				color: #fff;
				font-weight: 500;
				.menu-link-inner {
					padding: 0;
					color: #fff;
				}
			}
			&.active, &:focus, &:hover {
				.nav-link {
					.menu-link-inner {
						color: #fff;
						border: none;
					}
				}
			}
		}
		.nav-item-first-level {
			> .nav-link {
				background-color: $mobile-menu-lvl1-bg;
				.menu-link-inner {
					font-weight: 900;
					padding-top: 0;
				}
				.sub-arrow {
					line-height: 53px;
				}
			}
			& > .dropdown-menu {
				.nav-link {
					color: $mobile-menu-item-lvl2;
				}
				& > .dropdown-item {
					& > .nav-link {
						padding-left: 32px;
						background-color: #fff;
					}
				}
				.dropdown-third-level-menu {
					.nav-link {
						padding-left: 48px;
						background-color: $mobile-menu-dropdown-lvl3-bg;
					}
				}
			}
		}
		.dropdown-menu {
			box-shadow: none;
			margin-top: 0;
			.nav-link {
				background-color: $color-secondary;
				color: rgba(255, 255, 255, 0.8);
			}
			.dropdown-item {
				padding: 0;
				border: 0;
				.dropdown-third-level-menu {
					margin: 0;
				}
			}
		}
		.nav-item-first-level {
			&.active > .nav-link {
				background-color: $mobile-menu-lvl1-bg-active;
			}
		}
		.dropdown-second-level-menu, .dropdown-third-level-menu {
			& > .dropdown-item.active > .nav-link {
				font-weight: 900;
			}
		}

		.navbar-nav {
			.sub-arrow {
				border: none;
				margin: 0;
				top: 0;
				bottom: 0;
				width: 56px;
				height: auto;
				line-height: 49px;
				font-weight: 900;
				display: flex;
				align-items: center;
				justify-content: center;
				// &:before {
				// 	height: 100%;
				// }
			}
		}
	}

	.navbar-nav.sm-collapsible .sub-arrow::before {
		content: "";
		display: block;
		width: 12px;
		height: 7.4px;
		background-image: $mobile-menu-toggle-arrow-bg-image;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}
	.navbar-nav.sm-collapsible .show > a > .sub-arrow::before {
		content: "";
		transform: rotate(180deg);
	}
	.navbar-nav.sm-collapsible .nav-item-first-level > .nav-link .sub-arrow:before {
		background-image: url('/public/img/svg/ic/expand-white.svg');
	}
	
	.is-sticky {
		.main-menu {
			top: 71px;

			max-height: calc(100vh - 71px);
		}
	}
}