.footer {
	font-size: 15px;
	color: #fff;
	background: linear-gradient(135deg, #54534A 0%, #2B2B22 100%, #2B2B22 100%);
	hr {
		border-color: #fff;
		opacity: 0.2;
		margin-top: 0;
		margin-bottom: 0;
	}
	@media #{$extra-small-only} {
		.contact-details-phone {
			display: none;
		}
	}
}
.footer a:not(.button-alt) {
	color: #fff;
	&:hover, &:focus {
		color: $link-on-dark-hover;
		text-decoration: none;
	}
}
.footer-columns {
	padding-top: 48px;
	padding-bottom: 39px;
	.row-break {
		padding-bottom: 39px;
	}
}
.footer-bottom {
	padding: 13px 0;
	.social-link {
		margin-left: 24px;
	}
}
.footer-bottom-logo {
	padding: 10px 0;
	a {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: right;
	}
}
.footer-bottom-logo__hc {
	height: 21px;
	margin-bottom: 20px;
}
.footer-bottom-logo__luye {
	height: 30px;
	@media #{$large-up} {
		margin-right: 8px;
		align-self: flex-end;
	}
}
.footer-column-heading {
	color: #fff;
	opacity: 0.7;
	text-transform: uppercase;
	margin-bottom: 16px;
	letter-spacing: 1.5px;
}
.footer-column-sub-pages {
	padding-left: 0;
	list-style: none;
	.footer-column-sub-page:not(:last-child) {
		margin-bottom: 10px;
	}
}
.footer-column-navigation-entry:not(:last-child) {
	margin-bottom: 39px;
}
@media #{$extra-small-only} {
	.footer-column:not(:last-child) .footer-column-contact {
		margin-bottom: 39px;
	}
}

.footer-bottom-links {
	a {
		opacity: 0.7;
		font-size: 14px;
		&:not(:last-child) {
			margin-right: 24px;
		}
		&:hover, &:focus {
			color: $color-primary;
		}
	}
}

@media #{$medium-down} {
	.footer hr {
		display: none;
	}
	.footer-bottom {
		padding: 0;
	}
	.footer-bottom-element {
		padding: 13px 0;
		text-align: center;
	}
	.footer-bottom-links {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
}
@media #{$extra-small-only} {
	.footer {
		font-size: 14px;
	}
	.footer-bottom-links {
		a {
			font-size: 13px;
			&:not(:last-child) {
				margin-right: 16px;
			}
			&:nth-child(3) {
				margin-right: 0;
			}
		}
		#yump-credit-link {
			display: block;
		}
	}
}

.contact-details__mobile {
	@media #{$small-up:} {
		display: none;
	}
	@media #{$extra-small-only} {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
		
		.contact-details-mobile-phone__number {
			font-weight: normal;
			font-size: 14px;
		}
		.contact-details-mobile-phone {
			a.button-alt {
				display: flex;
				align-items: center;
				justify-content: space-between;

				padding-left: 24px;
				padding-right: 24px;
				.contact-details__mobile-phone-left {
					display: flex;
					align-items: center;
				}
				.contact-details-icon {
					opacity: 1;
				}
			}
		}
	}
}