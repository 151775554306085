#banner {
	position: relative;
	width: 100%;
	// background: $color-primary;
	@include linear-gradient(114deg, $gradient-start 0%, $gradient-end 100%);	
	// overflow: hidden;
	z-index: 900;	
	margin-bottom: calc(3*#{$spacing-unit});
	@media #{$medium-up}{ 
		/* This is to ensure initial content is hidden until page is loaded and javascript sets the height to the height of the viewport */
		height: 565px;
	}
	.banner-button-wrapper a {
	    @extend %button;
	    background: transparent;
	    border: 2px solid white;
	    color: white;
	    margin-top: 32px;
	    + a {
	    	margin-left: 20px;
	    }
	    &:hover {
	    	background-color: white;
	    	color: $color-primary;
	    }
	}
	.banner-content-wrapper {
		background-image: url('../img/svg/others/top-left-circles-for-top-banner.svg');
		background-repeat: no-repeat;
		@media #{$bs-mobile}{ 
			background-size: 30%;
		}
	}
}

/** Simple banner */
.simple-banner {
	// background-color: $default-banner-bg-color;
	color: $default-banner-text-color;
	position: relative;
	.banner-content-wrapper {
		text-align: center;
	}
}
.simple-banner__content {
	background-image: url('../img/svg/others/bottom-right-circle-for-top-banner.svg');
	background-repeat: no-repeat;
	background-position: 100% 105%;
	.animate-when-visible {
		opacity: 0;
	}
	@media #{$medium-up}{ 
		max-width: 500px;
	}
	@media #{$bs-mobile}{ 
		background-size: 35%;
	}
	.simple-banner-heading {
		text-transform: uppercase;
	}	
}

.simple-banner__content__align {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media #{$bs-mobile}{ 
		padding: 45px 0;
	}
	@media #{$medium-up}{ 
		height: 565px;
	}
}
.simple-banner-heading {
	@extend h1;
	color: $color-switch;
	max-width: 850px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	font-weight: 500;
	letter-spacing: 0;
	strong {
		font-weight: 700;
	}
	@media #{$medium-up}{ 
		margin-bottom: 20px;
	}
}
.simple-banner-heading > *:last-child,
.simple-banner-subheading > *:last-child {
	margin-bottom: 0;
}
.simple-banner-subheading {
	max-width: 750px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	font-size: 18px;
	line-height: 24px;
}
.simple-banner__bg-image {
	position: relative;
    height: 360px;
    width: 100%;
    background-size: cover;
    top: 0;	
    right: 0;
    opacity: 1;
    @media #{$medium-up}{ 
    	position: absolute;
    	width: 50%;
    	height: 565px;
    }
}

@media #{$medium-down}{ 
	.simple-banner-heading > * {
		font-size: 36px;
		line-height: 41px;
	}
	.simple-banner-heading > h1,
	.simple-banner-heading > h2,
	.simple-banner-heading > h3,
	.simple-banner-heading > h4,
	.simple-banner-heading > h5,
	.simple-banner-heading > h6 {
		margin-bottom: 11px;
	}
	.simple-banner-heading {
		margin-bottom: 11px;
	}
	.simple-banner-subheading > * {
		text-align: center;
		font-size: 17px;
		line-height: 23px;
	}
}

.banner-explore {
	@media #{$bs-mobile}{ 
		display: none;
	}
	@media #{$medium-up}{ 
		position: absolute;
		width: 100%;
		bottom: 0;
		text-align: center;
		color: white;
		text-transform: uppercase;
		font-size: 13px;
		font-weight: bold;
		z-index: 880;
		a {
			background: $bg-verylight-color;
			box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
			width: 48px;
			height: 48px;
			border-radius: 50%;
			display: inline-block;
			position: relative;
			top: 24px;
			&:hover {
				box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
			}
		}
		svg {
			margin-top: 14px;
			width: 20px;
			height: 20px;
		}
	}
}

//slides
// .slide-dark-overlay {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	width: 100%;
// 	height: 100%;
// 	background-color: #000;
// 	opacity: 0.5;
// }
// .banner-slide {
// 	width: 100%;
// 	height: 100%;
// 	position: relative;
// }
// .banner-slide-overlay {
// 	position: absolute;
// 	left: 0;
// 	width: 100%;
// 	/*height: 300px;*/
// 	/*The next 4  lines are the secret to vertical centering. Better than negative margin-top.*/
// 	top: 50%;
// 	-ms-transform: translateY(-50%);
// 	-webkit-transform: translateY(-50%);
// 	transform: translateY(-50%);
// 	text-align: center;
// 	color: white;
// 	z-index: 10000;
// 	padding: 0 40px;
// 	.container {
// 		max-width: 100%;
// 	}
// }
// .banner-slide-heading img,
// .banner-slide-subheading img {
// 	margin: 0 auto;
// 	max-width: 100%;
// }
// .banner-slide-heading {
// 	@extend h1;
// 	max-width: 850px;
// 	margin-left: auto;
// 	margin-right: auto;
// 	font-weight: bold;
// }
// .banner-slide-subheading {
// 	max-width: 750px;
// 	margin-left: auto;
// 	margin-right: auto;
// }
// .mega-link-overlay {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	width: 100%;
// 	height: 100%;
// }
// .banner-slides-wrapper {
// 	visibility: hidden;
// 	.slick-prev {
// 		left: 40px;
// 		z-index: 1;
// 	}
// 	.slick-next {
// 		right: 40px;
// 		z-index: 1;
// 	}
// 	.slick-dots {
// 	    position: absolute;
// 	    bottom: 25px;
// 	    width: 100%;
// 	    text-align: center;
// 	    li {
// 		    display: inline-block;
// 		    background: transparent;
// 		    width: 10px;
// 		    height: 10px;
// 		    border-radius: 50%;
// 		    border: 1px solid #fff;
// 		    margin-left: 5px;
// 		    margin-right: 5px;
// 		    cursor: pointer;
// 		}
// 		&.slick-active {
// 			background-color: #fff;
// 		}
// 		button {
// 			display: none;
// 		}
// 	}
// }


// @media (max-width: 1199px) and (min-width: 768px) {
// 	#banner.big-banner {
// 		height: 650px;
// 	}
// 	#banner {
// 		height: 550px;
// 	}
// }
// @media (max-width: 1023px) {
// 	.banner-slide-overlay {
// 		margin-top: 0;
// 		top: 50%;
// 		transform: translateY(-50%);
// 		-ms-transform: translateY(-50%);
// 		-webkit-transform: translateY(-50%);
// 	}
// }
// @media (max-width: 767px) {
	
// 	.banner-slides-wrapper .slick-prev {
// 		left: 10px;
// 	}
// 	.banner-slides-wrapper .slick-next {
// 		right: 10px;
// 	}
// 	.banner-slide-overlay .container {
// 		padding: 0;
// 	}

// 	#banner,
// 	#banner.big-banner {
// 		height: 450px;
// 		/*height: 100vh;*/
// 	}

// 	#banner .banner-button-wrapper a {
// 		padding: 6px 12px;
// 		font-size: 15px;
// 		border-width: 2px;
//     	/*margin-top: 20px;*/
// 	}

// 	.banner-slides-wrapper .slick-prev img,
// 	.banner-slides-wrapper .slick-next img {
// 		width: 30px;
// 	}

// 	.banner-slide-heading > * {
// 		font-size: 28px;
// 	}
// 	.banner-slide-heading > h1,
// 	.banner-slide-heading > h2,
// 	.banner-slide-heading > h3,
// 	.banner-slide-heading > h4,
// 	.banner-slide-heading > h5,
// 	.banner-slide-heading > h6 {
// 		margin-bottom: 25px;
// 	}
// 	.banner-slide-subheading > * {
// 		font-size: 15px;
// 		line-height: 1.5;
// 	}
// }