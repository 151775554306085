/*     Generated by Zeplin
    Copyright (C) 2017 - present Zeplin

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
 */

$greyish-brown: #3d3935;
$white: #ffffff;
$prussian-blue: #00427a;
$brownish-grey: #717067;
$black: #000000;
$greyish: #b8b7b3;
$white-two: #dcdcdc;
$marine-blue: #002e5e;
$robin-s-egg: #66e0ff;
$azure: #00aeef;
$cerulean: #007fbc;
$wisteria: #aa8fc3;
$medium-purple: #733c97;
$grape: #572772;
$wheat: #f7c088;
$pumpkin-orange: #f68b1f;
$brownish-orange: #c46f19;
$kelley-green: #008534;
$light-grey-green: #acd4ab;
$mid-green: #4aa147;
$dusty-blue: #6b86b1;

@mixin Type-BlackCaps-Heading-Level-1 {
  font-family: Lato;
  font-size: 64px;
  font-weight: 900;
  line-height: 0.75;
  letter-spacing: 3.9px;
  text-align: left;
  color: #34495e;
}

@mixin Type-Bold-Heading-Level-1 {
  font-family: Lato;
  font-size: 64px;
  font-weight: bold;
  line-height: 0.75;
  letter-spacing: 0.7px;
  text-align: left;
  color: #34495e;
}

@mixin Text-Style-2 {
  font-family: HouschkaPro;
  font-size: 50px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: $white;
}

@mixin Other-COUNTERS-PURPLE-CENTER {
  font-family: HouschkaPro;
  font-size: 50px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -2px;
  text-align: center;
  color: $wisteria;
}

@mixin H1-Center-dark {
  font-family: HouschkaPro;
  font-size: 50px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #36342e;
}

@mixin H1-Left-Blue {
  font-family: HouschkaPro;
  font-size: 50px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -1px;
  text-align: left;
  color: $prussian-blue;
}

@mixin H1-Left {
  font-family: HouschkaPro;
  font-size: 50px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -1px;
  text-align: left;
  color: $prussian-blue;
}

@mixin Text-Style {
  font-family: HouschkaPro;
  font-size: 50px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: $white;
}

@mixin Type-BlackCaps-Heading-Level-2 {
  font-family: Lato;
  font-size: 48px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 3.9px;
  text-align: left;
  color: #34495e;
}

@mixin Type-Bold-Heading-Level-3 {
  font-family: Lato;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.7px;
  text-align: left;
  color: #34495e;
}

@mixin H2-Center-Blue {
  font-family: HouschkaPro;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: -0.8px;
  text-align: center;
  color: $prussian-blue;
}

@mixin H2-Left-White {
  font-family: HouschkaPro;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: -0.8px;
  text-align: left;
  color: #fafafa;
}

@mixin H2-Left-Orange {
  font-family: HouschkaPro;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: -0.8px;
  text-align: left;
  color: $pumpkin-orange;
}

@mixin H2-Center-Orange {
  font-family: HouschkaPro;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: -0.8px;
  text-align: center;
  color: $pumpkin-orange;
}

@mixin H2-Left-Green {
  font-family: HouschkaPro;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: -0.8px;
  text-align: left;
  color: $kelley-green;
}

@mixin H2-Left-Purple {
  font-family: HouschkaPro;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: -0.8px;
  text-align: left;
  color: $grape;
}

@mixin H2-Center-Green {
  font-family: HouschkaPro;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: -0.8px;
  text-align: center;
  color: $kelley-green;
}

@mixin H2-Center-Purple {
  font-family: HouschkaPro;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: -0.8px;
  text-align: center;
  color: $grape;
}

@mixin H2-Left-Blue {
  font-family: HouschkaPro;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.13;
  letter-spacing: -0.8px;
  text-align: left;
  color: $prussian-blue;
}

@mixin Type-BlackCaps-Heading-Level-4 {
  font-family: Lato;
  font-size: 36px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 2.9px;
  text-align: left;
  color: #34495e;
}

@mixin H1-Left-Blue-M {
  font-family: HouschkaPro;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: -0.7px;
  text-align: left;
  color: $prussian-blue;
}

@mixin H1-Center-White-M {
  font-family: HouschkaPro;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: -0.7px;
  text-align: center;
  color: $white;
}

@mixin H1-Center-Dark-M {
  font-family: HouschkaPro;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: -0.7px;
  text-align: center;
  color: #36342e;
}

@mixin Type-BlackCaps-Heading-Level-5 {
  font-family: Lato;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 3.1px;
  text-align: left;
  color: #34495e;
}

@mixin Type-Bold-Heading-Level-5 {
  font-family: Lato;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.7px;
  text-align: left;
  color: #34495e;
}

@mixin H2-Center-Orange-M {
  font-family: HouschkaPro;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: $pumpkin-orange;
}

@mixin H2-Center-Green-M {
  font-family: HouschkaPro;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: $kelley-green;
}

@mixin H2-Left-Blue-M {
  font-family: HouschkaPro;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: $prussian-blue;
}

@mixin H2-Center-Blue-M {
  font-family: HouschkaPro;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: $prussian-blue;
}

@mixin H2-Center-Purple-M {
  font-family: HouschkaPro;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.13;
  text-align: center;
  color: $grape;
}

@mixin H3-Center-Purple-M {
  font-family: HouschkaPro;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.08;
  text-align: center;
  color: $grape;
}

@mixin H3-Center-Dark-M {
  font-family: HouschkaPro;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.08;
  text-align: center;
  color: #455148;
}

@mixin H3-Left-Blue-M {
  font-family: HouschkaPro;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.08;
  text-align: left;
  color: $marine-blue;
}

@mixin H3-Left-Dark-M {
  font-family: HouschkaPro;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.08;
  text-align: left;
  color: #455148;
}

@mixin H4-Left-Dark-M {
  font-family: HouschkaPro;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
  text-align: left;
  color: #455148;
}

@mixin H4-Left-Blue-M {
  font-family: HouschkaPro;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
  text-align: left;
  color: #5a2c8f;
}

@mixin H4-Center-Dark-M {
  font-family: HouschkaPro;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
  text-align: center;
  color: #455148;
}

@mixin H4-Center-Blue-M {
  font-family: HouschkaPro;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.09;
  text-align: center;
  color: $marine-blue;
}

@mixin Intro-Left {
  font-family: Lato;
  font-size: 22px;
  line-height: 1.32;
  text-align: left;
  color: #54534a;
}

@mixin H4-Left-Orange-M {
  font-family: HouschkaPro;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: $pumpkin-orange;
}

@mixin H4-Left-Blue-M {
  font-family: HouschkaPro;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: $marine-blue;
}

@mixin H4-Left-white {
  font-family: HouschkaPro;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: $white;
}

@mixin H4-Left-Purple-M {
  font-family: HouschkaPro;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: $grape;
}

@mixin H4-Left-Green-M {
  font-family: HouschkaPro;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: #008569;
}

@mixin Other-Counters {
  font-family: HouschkaPro;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  text-align: left;
  color: $white;
}

@mixin Other-Counters-Center {
  font-family: HouschkaPro;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  color: $white;
}

@mixin Other-Quote {
  font-family: HouschkaPro;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #455148;
}

@mixin Body-Intro-Left {
  font-family: Lato;
  font-size: 18px;
  line-height: 1.33;
  text-align: left;
  color: $brownish-grey;
}

@mixin Body-Intro-Center-White {
  font-family: Lato;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: $white;
}

@mixin Body-Intro-Center {
  font-family: Lato;
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: $brownish-grey;
}

@mixin Body-Intro-Left-White {
  font-family: Lato;
  font-size: 18px;
  line-height: 1.33;
  text-align: left;
  color: $white;
}

@mixin Other-Quote-M {
  font-family: HouschkaPro;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.35;
  text-align: left;
  color: #455148;
}

@mixin Body-Intro-Left-M {
  font-family: Lato;
  font-size: 17px;
  line-height: 1.35;
  text-align: left;
  color: $brownish-grey;
}

@mixin Body-Intro-Center-M {
  font-family: Lato;
  font-size: 17px;
  line-height: 1.35;
  text-align: center;
  color: $brownish-grey;
}

@mixin Body-Intro-Center-White-M {
  font-family: Lato;
  font-size: 17px;
  line-height: 1.35;
  text-align: center;
  color: $white;
}

@mixin Body-Body-Heading {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.31;
  text-align: left;
  color: #455148;
}

@mixin Nav-White {
  font-family: HouschkaPro;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: $white;
}

@mixin Nav-Dark {
  font-family: HouschkaPro;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #36342e;
}

@mixin Body-Body-Center {
  font-family: Lato;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: #54534a;
}

@mixin Body-Body-Left {
  font-family: Lato;
  font-size: 16px;
  line-height: 1.38;
  text-align: left;
  color: $brownish-grey;
}

@mixin Body-Body-Left-White {
  font-family: Lato;
  font-size: 16px;
  line-height: 1.38;
  text-align: left;
  color: $white;
}

@mixin Body-Body-Left-White-M {
  font-family: Lato;
  font-size: 15px;
  line-height: 1.4;
  text-align: left;
  color: $white;
}

@mixin Body-Body-Left-M {
  font-family: Lato;
  font-size: 15px;
  line-height: 1.4;
  text-align: left;
  color: $brownish-grey;
}

@mixin Form-Name {
  font-family: Lato;
  font-size: 15px;
  text-align: left;
  color: #54534a;
}

@mixin Footer-Footer-white-left {
  font-family: Lato;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: $white;
}

@mixin Body-Body-Center-M {
  font-family: Lato;
  font-size: 15px;
  line-height: 1.4;
  text-align: center;
  color: $brownish-grey;
}

@mixin Other-WHITE-70 {
  font-family: HouschkaPro;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  text-align: left;
  color: $white;
}

@mixin Form-Button-Label {
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #4a4a4a;
}

@mixin Nav-Sidenav-2nd-Level-Active {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: $white;
}

@mixin Nav-Sidenav-3rd-Level-Active {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: $white;
}

@mixin Nav-Sidenav-2nd-Level-Default {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: $white;
}

@mixin Form-Title-inside-input {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: $brownish-grey;
}

@mixin Nav-Second-level-menu-80 {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: $white;
}

@mixin Nav-70-White-M {
  font-family: HouschkaPro;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: $white;
}

@mixin Nav-Dark-M {
  font-family: HouschkaPro;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #36342e;
}

@mixin Nav-White-M {
  font-family: HouschkaPro;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: $white;
}

@mixin Form-Label-name-M {
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.4px;
  text-align: left;
  color: #455148;
}

@mixin Form-Placeholder-text {
  font-family: Lato;
  font-size: 14px;
  text-align: left;
  color: $greyish;
}

@mixin Nav-Breadcrumb {
  font-family: Lato;
  font-size: 14px;
  text-align: left;
  color: $white;
}

@mixin Footer-Footer-white-left-M {
  font-family: Lato;
  font-size: 14px;
  line-height: 1.57;
  text-align: left;
  color: $white;
}

@mixin Nav-Menu-item-Hover {
  font-family: Lato;
  font-size: 14px;
  text-align: left;
  color: $white;
}

@mixin Nav-Menu-items {
  font-family: Lato;
  font-size: 14px;
  text-align: left;
  color: $brownish-grey;
}

@mixin Nav-Sidenav-3rd-Level-Default {
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: $white;
}

@mixin Form-Placeholder-text {
  font-family: OpenSans;
  font-size: 14px;
  text-align: left;
  color: #515151;
}

@mixin BUTTON-White {
  font-family: Lato;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 0.9px;
  text-align: center;
  color: $white;
}

@mixin BUTTON-Blue-Black {
  font-family: Lato;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 0.9px;
  text-align: center;
  color: $azure;
}

@mixin BUTTON-White-Left {
  font-family: Lato;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 0.9px;
  text-align: left;
  color: $white;
}

@mixin BUTTON-Darker {
  font-family: Lato;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 0.9px;
  text-align: center;
  color: #455148;
}

@mixin BUTTON-WHITE-LEFT-SECONDARY-MENU {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: left;
  color: $white;
}

@mixin BUTTON-Purple {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: left;
  color: $medium-purple;
}

@mixin BUTTON-Blue-Left {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: left;
  color: $azure;
}

@mixin BUTTON-Lime {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: left;
  color: #96aa24;
}

@mixin BUTTON-Dark {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: center;
  color: $greyish-brown;
}

@mixin BUTTON-Blue {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.9px;
  text-align: center;
  color: $azure;
}

@mixin Nav-Second-Level-Menu-100 {
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: $white;
}

@mixin BUTTON-NEW {
  font-family: OpenSans;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: $white;
}

@mixin OtherInfo {
  font-family: Lato;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #b70d5c;
}

@mixin Form-Label {
  font-family: Lato;
  font-size: 12px;
  text-align: left;
  color: $brownish-grey;
}