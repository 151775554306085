//Theme Base Colour
$theme-color: 'orange';

// Colours (All themes - but used in this settings file so need them here)
$color-verylightgrey: 			#fafafa; // Tile bg color
$color-brownish-grey: 			#717067; // text default
$color-greyish-brown:			#3d3935; //sidebar text
$color-white: 					#fff; 

// Colours (mental specific)
$color-orange: 					#f68b1f; //button orange	
$color-brownishorange:			#c46f19; //
$color-lightorange: 			#f68b1f; //banner light
$color-darkorange:				#f05324; //banner dark
$color-wheat:					#f7c088; //stats text
$color-otherorange: 			#50a45a; //sidebar background
$color-yetanotherorange:		#ef6d20; //mobile menu button, mobile active item bg

$color-primary: $color-orange; 
$color-secondary: $color-brownishorange; 
$color-tertiary: $color-brownishorange;

$selection-color: $color-primary;
$selection-text-color: #fff;

//typography
$font-color: $color-brownish-grey;
$highlight-color: $selection-color;
$highlight-text-color: $selection-text-color;
$heading-color: $color-primary;
$link-color: $color-primary;
$link-color-hover: $color-secondary;

//backgrounds
$color-pagination-bg: $color-primary;
$color-sidebar-bg: $color-primary;

//gradients
$gradient-start: $color-lightorange;
$gradient-end: $color-darkorange;
$gradient-hr-bg: linear-gradient(to right, $gradient-start, $gradient-end);

//block specific
$color-stats: $color-wheat;
$color-tile-arrowbg: $color-secondary;
$stat-counter-bg-image: url('/public/img/jpg/counters-mental-bg@2x.jpg');
$stat-counter-bg-position-desktop: 100% 50%;

$color-search-bar: $color-primary;

$sidebar-triangle-background: url('/public/img/png/ic/triangle-orange.png');
$color-sidebarlink: $color-white;

// Mobile
$mobile-menu-toggle-arrow-bg-image: url('/public/img/svg/ic/expand-mental.svg'); //
// --Menu
$mobile-menu-toggler-bg: $color-orange;
//lvl1
$mobile-menu-lvl1-bg: $color-primary; 
$mobile-menu-lvl1-bg-active: $color-yetanotherorange;
//lvl2
$mobile-menu-item-lvl2: $color-greyish-brown;
//lvl3
$mobile-menu-item-lvl3: $color-greyish-brown;