$body-font-family: 'Lato', sans-serif;;
$heading-font-family: 'HouschkaPro';

$default-font-size-desktop-large: 18px;
$default-font-size-desktop-standard: 16px;
$default-font-size-mobile-large: 17px;
$default-font-size-mobile-standard: 15px;

$default-line-height-desktop-large: 24px;
$default-line-height-desktop-standard: 22px;
$default-line-height-mobile-large: 23px;
$default-line-height-mobile-standard: 21px;

$body-font-weight-bold: 700;

/** Heading font size and line heights are defined in another _typography.scss, no point definding all variables and then copy that in another file */