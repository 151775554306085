body {
	@extend %body-style;
}
blockquote {
	@include Section-Subheading;
	padding: 24px;
	font-style: italic;
    background-color: $color-verylightgrey;
    @media #{$large-up} {
        padding: 32px;
    }
	@media #{$medium-up} {
		text-align: left;
	}

	&:before {
		content: '';
		display: block;
		width: 31px;
		height: 24px;
		margin-bottom: calc(2*#{$spacing-unit}); 
		background-image: url('../img/svg/others/quote-#{$theme-color}.svg');
		@media #{$bs-mobile}{
			margin: 0 auto 16px;
		}
	}
	& + blockquote {
		padding-top: 0;
		margin-top: -24px;
		@media #{$large-up} {
	        margin-top: -32px;
	    }
		&:before {
			display: none;
		}
	}
	&.quote-by {
		font-weight: bold;
		margin-bottom: 0;
		font-size: 16px;
		font-style: normal;
		& + blockquote {
			font-size: 16px;
			font-style: normal;
		}
	}
}
.rich-text {
	*:not(blockquote) + blockquote {
		margin-top: 32px;
	}
	blockquote + *:not(blockquote) {
		margin-top: 32px;
	}
}

hr {
	border-width: 1px;
	border-color: $color-hr;
}
h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font-family;
}
h1 {
	font-size: 38px;
	line-height: 43px;
	letter-spacing: -0.8px;
	font-weight: 500;
	color: $heading-color;
	margin-bottom: 8px;
	@media #{$large-up} {
		font-size: 50px;
		line-height: 55px;
		letter-spacing: -1px;
		margin-bottom: 19px;
	}
}
h2 {
	font-size: 32px;
	line-height: 36px;
	font-weight: 500;
	color: $heading-color;
	margin-bottom: 12px;
	@media #{$large-up} {
		font-size: 40px;
		line-height: 45px;
		letter-spacing: -0.8px;
		margin-bottom: 14px;
	}
}
h3 {
  	font-size: 26px;
  	font-weight: 600;
  	line-height: 1.13;
  	letter-spacing: -0.8px;
	color: $heading-color;
	margin-bottom: 11px;
}
h4, h3.h4, h2.h4 {
	font-size: 18px;
	line-height: 24px;
	font-weight: bold;
	color: $color-tertiary;
	margin-bottom: 7px;
	letter-spacing: 0.2px;
}
h5 {
	font-size: 16px;
	line-height: 21px;
	font-weight: bold;
	font-family: $body-font-family;
	color: $color-midgrey;
	margin-bottom: 7px;
}
h6 {
	font-size: 14px;
	line-height: 17px;
	font-weight: 800;
	letter-spacing: 1px;
	color: $color-midgrey;
}
.rich-text {
	h1, h2 {
		margin-top: 35px;
		@media #{$large-up} {
			margin-top: 38px;
		}
	}
	h3, h4, h5, h6 {
		margin-top: 21px;
	}
}

a {
	color: $link-color;
	transition: all 0.4s;
	&:hover, &:focus {
		color: $link-color-hover;
	}
}

p {
	&.no-bottom-gap {
		margin-bottom: 0;
	}
	&.align-center {
		text-align: center;
	}
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #B3BBC9;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #B3BBC9;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #B3BBC9;
}
:-moz-placeholder { /* Firefox 18- */
  color: #B3BBC9;
}

::-moz-selection {
	background: $selection-color;
	color: $selection-text-color;
}
::selection {
  	background: $selection-color;
  	color: $selection-text-color;
}