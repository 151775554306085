[v-cloak] {
    display: none;
}

.icon-facebook {
	width: 9px;
	height: auto;
}
.icon-twitter {
	width: 21px;
	height: auto;
}
.icon-linkedin {
	width: 17px;
	height: auto;
}
.social-link {
	picture {
		display: inline-block;
    	vertical-align: middle;
	}
	svg path {
		transition: all 0.4s;
	}
	&:hover, &:focus {
		svg {
    		path {
    			fill: $link-on-dark-hover;
    		}
    	}
	}
}

.highlight {
    background: $highlight-color;
    color: $highlight-text-color;
}

.small-print {
	font-size: 12px;
}

.spinner-container {
	padding: 16px 16px 64px;
	.loading-text {
		margin-top: 64px;
		font-family: $heading-font-family;
		font-size: 24px;
		font-weight: 900;
		text-align: center;
		color: $color-secondary;
	}
}

.no-list-item-section {
	margin-top: 32px;
	margin-bottom: 32px;
	font-family: $heading-font-family;
	font-size: 18px;
	font-weight: bold;
	line-height: 24px;
	text-align: left;
	color: $color-tertiary;
}

.address-table {
	tr > td:first-child {
		padding-right: 15px;
		font-weight: 700;
		vertical-align: top;
	}
	tr.address-table__type td {
		padding-top: 5px;
	}
}

/** Emergency department banner */
#emergency-feed {

	background-color: $color-emergency-red;
	padding: 8px 0;
	.emergency-feed-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.emergency-feed-link-wrapper {
			a {
				color: $color-white;
				text-decoration: underline;
				&:hover, &:focus, &:active {
					text-decoration: none;
				}
			}

			// to avoid banner jumping when the time become available for user
			@media screen and (min-width: 580px) {
				padding-top: 6px;
				padding-bottom: 6px;
			}
		}
		.emergency-time-widget {
			display: none;

			padding: 6px 12px;
			background-color: $color-grey-alt;
			border-radius: 6px;
			color: $color-white;

			.fa-clock {
				margin-right: 5px;
			}
		}

		@media screen and (max-width: 579px) {
			justify-content: center;
			flex-wrap: wrap;
			padding: 8px 0;

			.emergency-time-widget {
				width: 100%;
				margin-top: 12px;
				text-align: center;
			}
		}
	}


	&.show {
		.emergency-feed-inner {
			.emergency-time-widget {
				display: block;
			}
		}
	}
}