$spacing-unit: 8px;
//usage - margin-bottom: calc(4*#{$spacing-unit}); for 32 px

$section-padding-large: 64px;
$section-padding-medium: 48px;
$section-padding-small: 32px;

$gutter-super-large: 48px;
$gutter-large: 32px;
$gutter-standard: 24px;
$gutter-small: 16px;
$gutter-super-small: 8px;

/* Bootstrap breakpoints */
$extra-small-breakpoint: 576px; // Extra small <576px	
$small-breakpoint: 768px; //Small  ≥576px	
$medium-breakpoint: 992px; //Medium ≥768px	
$large-breakpoint: 1200px; //Large  ≥992px	
$extra-large-breakpoint: 1600px; //Extra large ≥1200px

$extra-small-range: (0, $extra-small-breakpoint - 1); //0 - 575
$small-range: ($extra-small-breakpoint, $small-breakpoint - 1); //576 - 767
$medium-range: ($small-breakpoint, $medium-breakpoint - 1); //768 - 991
$large-range: ($medium-breakpoint, $large-breakpoint - 1); //992 - 1199
$extra-large-range: ($large-breakpoint, 9999px); //1600 - 9999

$mq-extra-small-max: nth($extra-small-range, 2); //576
$mq-small-min: nth($small-range, 1); //576
$mq-small-max: nth($small-range, 2); //991
$mq-medium-min: nth($medium-range, 1); //768
$mq-medium-max: nth($medium-range, 2); //1199
$mq-large-min: nth($large-range, 1); //1200
$mq-large-max: nth($large-range, 2); //1599
$mq-extra-large-min: nth($extra-large-range, 1); //1600
$mq-extra-large-max: nth($extra-large-range, 2); //9999

// Usage Media Queries: 
//@media #{$medium-down}{} 
$screen: "only screen";
$extra-small-only: "#{$screen} and (max-width: #{$mq-extra-small-max})"; //max 576
$small-up: "#{$screen} and (min-width:#{$mq-small-min})"; //min 576
$extra-small-to-small: "#{$screen} and (min-width:#{$mq-small-min}) and (max-width: #{$mq-small-max})";
$bs-mobile: "#{$screen} and (max-width:#{$mq-small-max})"; //max 767
$medium-up: "#{$screen} and (min-width:#{$mq-medium-min})"; //min 768
$medium-down: "#{$screen} and (max-width:#{$mq-medium-max})"; //max 991
$medium-only: "#{$screen} and (min-width:#{$mq-medium-min}) and (max-width: #{$mq-medium-max})"; //between 768 and 991
$large-up: "#{$screen} and (min-width:#{$mq-large-min})"; //992 up
$large-down: "#{$screen} and (max-width:#{$mq-large-max})"; //1199 down
$large-only: "#{$screen} and (min-width:#{$mq-large-min}) and (max-width:#{$mq-large-max})"; //between 992 and 1199
$extra-large-up: "#{$screen} and (min-width:#{$mq-extra-large-min})"; //1200 up
$extra-large-only: "#{$screen} and (min-width:#{$mq-extra-large-min}) and (max-width:#{$mq-extra-large-max})"; //between 1200 and 1599

// Cheatsheet:
// inlining svg usage:
// {{ craft.inlin.er(statsBlock.icon.first().url) | raw }}

//image with focus point
/*
{% if entry.imageWithFocusPoint %}
{# {% set bgImageThumbConfig = { width: 1900 } %} #}
{# {{dump(bgImage | yumpimager(bgImageThumbConfig))}} #}
{% set bgImage = entry.imageWithFocusPoint.first() %}
	{% if bgImage %}
		<div class="simple-banner__bg-image" style="background-image: url('{{ bgImage.url }}'); background-size: cover;{% if bgImage %} background-position: {{ bgImage.focusPctX }}% {{ bgImage.focusPctY }}%;{% endif %}"></div>	
	{% endif %}
{% endif %}
*/
