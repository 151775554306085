.specialist-page-header, .specialist-page-body {
	& > .container {
		@media #{$small-up} {
			width: 750px;
		}
	}
}
.specialist-page-header {
	padding-top: 32px;
	padding-bottom: 32px;
	background-image: linear-gradient(to right, transparentize($color-primary, 0.95), transparentize($color-secondary, 0.95));
	@media #{$medium-up} {
		padding-top: 64px;
		padding-bottom: 64px;
	}
}
.specialist-page-header-circles {
	display: flex;
	.aspect-ratio-1-1 {
		width: calc(50% + 20px);
		max-width: 175px;
		max-height: 175px;
		position: relative;
	}
	@media #{$bs-mobile} {
		margin-bottom: 32px;
		justify-content: center;
	}
}
.aspect-ratio-1-1 {
	&:before {
		content: "";
    	display: block;
		padding-top: 100%;
	}
}
.specialist-page-header-circles-photo {
	img {
		border-radius: 50%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}
.specialist-page-header-circles-name {
	border-radius: 50%;
	background-color: $color-specialist-page-circle-bg;
	font-size: 22px;
	letter-spacing: -0.4px;
	line-height: normal;
	font-family: $heading-font-family;
	font-weight: 500;
	color: #fff;
	// display: inline-flex;
	// text-align: center;
	// align-items: center;

	&:before {
		content: "";
    	display: block;
		padding-top: 100%;
	}
	.specialist-page-header-circles-name-content {
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		padding-left: 8px;
		padding-right: 8px;
	}
}
.specialist-page-header-circles-photo + .specialist-page-header-circles-name {
	margin-left: -20px;
}
.specialist-page-header-specialites {
	display: flex;
	align-items: center;
	@media #{$bs-mobile} {
		padding-left: 24px;
		justify-content: center;
	}
	h3 {
		font-size: 26px;
		line-height: 28px;
		font-weight: 600;
		color: $color-tertiary;
		margin-bottom: 6px;
	}
	ul {
		@include Body-Intro-Left;
		padding-left: 20px;
		@media #{$bs-mobile} {
			padding-left: 0;
		}
	}
}


// Body
.specialist-page-body-dictionary {
	.contact-details-icon {
		path {
			fill: #3d3935;
		}
	}
	dl {
		margin-bottom: 21px;
		@media #{$medium-up} {
			// margin-bottom: 32px;
		}
		@media #{$bs-mobile} {
			dt {
				margin-bottom: 8px;
			}
		}
	}

	.body-neo-wrapper {
		.matrix-block-wrapper {
			&:last-child {
				dl {
					margin-bottom: 0;
				}
			}
		}
	}
}

.specialist-portfolio-entry {
	.specialist-portfolio-entry-content {
		& > *:not(:last-child) {
			margin-bottom: 1rem;
		}
	}
	.file-attachment-link {
		display: block;
	}
	dd {
		& > .rich-text:last-child, & > .reusable-snippet-sections:last-child .rich-text {
				*:last-child {
					margin-bottom: 0;
				}
		}
		& > .file-attachment:not(:last-child) {
			margin-bottom: 1rem;
		}
		& > .reusable-snippet-sections:not(:last-child) {
			margin-bottom: 1rem;
		}
		.reusable-snippet-sections {
			.matrix-block-inside-section:not(.matrix-block-dividingLine):not(:last-child) {
			    margin-bottom: 1rem;
			}
		}
	}
	
}
.specialists-filters {
	#specialty {
		font-size: 14px;
		font-weight: bold;
		color: $font-color;
	}	
}

.specialist-page-wrapper {
	.split-line {
		width: 100%;
		height: 2px;
		background: $gradient-hr-bg;
	}
}