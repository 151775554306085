// .matrix-block-wrapper-teamMembers {
// 	@include General-Section-Margin;
// 	margin-top: 0;
// 	@media #{$large-up} {
// 		margin-top: 0;
// 	}
// }
.team-member {
	margin-bottom: 48px;
	&:first-child {
		margin-top: 48px;
	}
	.team-member-thumb {
		width: 100%;
		border-radius: 50%;
		@media (max-width: 767px) {
			max-width: 160px;
		}
	}
	.team-member-description {
		& > *:last-child {
			margin-bottom: 0;
		}
	}
	.team-member-details-wrapper {
		.member-name {
			margin-bottom: 11px;
		}
		.member-title {
			margin-bottom: 7px;
			color: $color-tertiary;
		}
	}
	@media #{$bs-mobile} {
		// margin-bottom: 32px;
		.team-member-thumb-wrapper,
		.member-name,
		.member-title,
		.team-member-description {
			text-align: center;
		}
		.team-member-thumb-wrapper {
			margin-bottom: 18px;
		}
	}
}

.team-members-section-heading {
	font-weight: 700;
}