// .ratio-16-9-container {
// 	padding-top: 56.25%;
// 	position: relative;
//     width: 100%;
// }
// .ratio-16-9-element {
// 	position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
// }
.tiles-intro {
	text-align: center;
	max-width: 620px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: calc(4*#{$spacing-unit});
	@media #{$medium-up} {
		margin-bottom: calc(6*#{$spacing-unit});
	}
	p {
		@include Section-Subheading;
	}
}
.tiles-wrapper {
	margin-bottom: calc(-1*4*#{$spacing-unit});
	.tile-item {
		margin-bottom: calc(4*#{$spacing-unit});

		display: flex;
		& > .card-wrap-link {
			display: flex;
			& > .card {
				flex: 1;
			}
		}
	}
}
a.card-wrap-link {
	display: inline-block;
	width: 100%;
	text-decoration: none;
	&:hover {
		text-decoration: none;
		.card__image-container__image {
			transform: scale(1.1);
		}
	}
}
.card {
	margin-left: 0;
	margin-right: 0;
	
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	background: $bg-verylight-color;
	overflow: hidden;
	&:hover {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	}
	.card__image-container {
		overflow: hidden;
		.card__image-container__image {
			transition: transform .3s;
		}
	}
	& > .card__image-container {
		@media #{$bs-mobile} {
			display: none;
		}
	}
	.card-block__text-wrap {
		color: $font-color;
		padding-bottom: calc(3*#{$spacing-unit});
		padding-top: calc(2*#{$spacing-unit});
		padding-left: calc(2*#{$spacing-unit});
		padding-right: calc(2*#{$spacing-unit});	
		display: inline-block;
		.card-text {
			font-size: 15px;
			line-height: 21px;
		}
	}
	.card-title {
		background-color: $color-tile-titlebg;
		margin-bottom: 0;
		.card-title-link {
			color: $color-switch;
			font-family: $heading-font-family;
			padding: 13px 56px 13px 16px;
			display: inline-block;
			width: 100%;
			position: relative;
			min-height: 48px;
			line-height: 22px;
			text-decoration: none;
			span {
				position: absolute;
				right: 0;
				top: 0;
				background: $color-tile-arrowbg;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				padding: 12px 13px;
				img {
					height: 19px;
					width: 19px;
				}
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
}
.tiles-wrapper .card-deck {
	margin-left: -15px;
	margin-right: -15px;
}

.matrix-block-subpageTiles {
	@include General-Section-Margin;
}

.load-more-button {
	margin-top: 32px;
}