.sidebar {
	ul {
		list-style-type: none;
		padding-left: 0;
		li {
			position: relative;
			&.in-active-path, &.active {
				& > a {
					opacity: 1;
					font-weight: bold;
				}
			}
			a {
				display: block;
				color: $color-sidebarlink;
				font-size: 14px;
				letter-spacing: 0.5px;

				padding: 14px 16px 17px;
				&:hover, &:focus {
					text-decoration: none;
					background-color: $color-tertiary;
					outline: 0;
				}
			}
			&.has-children {
				& > a {
					padding-right: 48px;
				}
			}

			&.sidebar-nav-child-item {
				& > a {
					padding-left: 32px;
				}
			}
			&.active {
				& > a {
					background-color: $color-sidebarlinkactive-bg;
					position: relative;
					color: $color-sidebarlinkactive;
				}

				.sidebar-nav-item-triangle {
				    position: absolute;
				    right: -21px;
				    top: 0;
				    bottom: 0;
				    width: 23px;
				    background: $sidebar-triangle-background;
				    background-size: 100% 100%;
				    background-repeat: no-repeat;
				}

					// &:after {
					//     content: " ";
					//     width: 0;
					//     height: 0;
					//     border-top: 26px solid transparent;
					//     border-bottom: 26px solid transparent;
					//     border-left: 23px solid #002e5e;
					//     position: absolute;
					//     right: -23px;
					//     top: 0;
					//     bottom: 0;
					// }
			}
		}
	}
}
.sidebar-nav-item-dropdown-toggle {
	position: absolute;
	right: 0;
	top: 0;
	line-height: 48px;
	width: 48px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	z-index: 1;
	svg {
		width: 12px;
		height: auto;
		transition: all 0.3s;
		path {
			fill: $color-mobile-sidebar-toggle-color;
		}
	}
	&:hover {
		svg {
			path {
				fill: $color-mobile-sidebar-toggle-color-hover;
			}
		}
	}
}
.sidebar-nav-item {
	& > a {
		opacity: $opacity-sidebar-link-primary;
		font-weight: bold;
	}
	&.expanded {
		.sidebar-nav-item-dropdown-toggle {
			svg {
				transform: rotate(180deg);
			}
		}
	}
}
.sidebar-nav-child-item {
	& > a {
		opacity: $opacity-sidebar-link-secondary;
	}
}
.sidebar-nav-sub-menu {
	display: none;
}
.sidebar-mobile-current-item-span {
	display: none;
}

@media #{$medium-down} {
	.sidebar {
		ul {
			li {
				a {
					background-color: $mobile-subnav-defaultbg;
					opacity: 1;
					color: $color-mobile-subnavlink;
					font-weight: 400;
					&:hover, &:focus {
						background-color: $mobile-subnav-activebg;
					}
				}
				&.active {
					a {
						color: $color-mobile-subnavlink;
						font-weight: 900;
						background-color: $mobile-subnav-activebg;
						&:hover, &:focus {
							background-color: $mobile-subnav-activebg;
						}
					}
				}
				&.has-children {
					& > a {
						padding-right: 0;
					}
				}
				.sidebar-nav-item-dropdown-toggle {
					display: none;
				}
			}
		}
	}

	.sidebar-nav-item-triangle {
		display: none;
	}
	.sidebar-mobile-current-item-span {
		display: block;
		color: #fff;
		font-size: 14px;
		letter-spacing: 0.5px;
		font-weight: 900;
		padding: 14px 16px 17px;
		background-color: $mobile-subnav-dropdown-bg;

		margin-bottom: 4px;
		position: relative;
		border-radius: 4px;
		.sidebar-nav-item-dropdown-toggle {
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&.mobile-sidebar-expanded {
			background-color: $mobile-subnav-dropdown-bg-expanded;
			svg {
				transform: rotate(180deg);
			}
		}
	}
	.sidebar-nav-sub-menu {
		// display: none !important;
	}
}
.sidebar-nav {
	display: none;
	@media #{$large-up} {
		// display: block !important;
	}
}