.pagination {
	text-align: center;
	display: block;
	ol {
		list-style: none;
		li {
			display: inline-block;
		}
		.page-item {
			padding: 0 3px;
		}
	}
	a {
		cursor: pointer;
		border-radius: 50%;
	}
	a, a:hover, a:focus {
		text-decoration: none;
	}
	span, a {
		display: inline-block;
		width: 30px;
		height: 30px;
		line-height: 30px;
		@media (max-width: 767px) {
			width: 26px;
			height: 26px;
			line-height: 26px;
		}
	}
	a:hover {
		text-decoration: none;
	}
	.current, a:hover {
		color: $color-switch;
		background: $color-pagination-bg;
		border-radius: 50%;
	}
	.current {
		font-weight: 700;
	}
	.arrow-first, .arrow-last {
		a {
			font-size: 26px;
			line-height: 24px;
			@media (max-width: 767px) {
				font-size: 24px;
    			line-height: 20px;
			}
		}
	} 
}
