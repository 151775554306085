.neo-section {
    padding: $section-padding-medium 0;
    @media #{$medium-down} {
        padding-top: $section-padding-small;
        padding-bottom: $section-padding-small;
    }
}

.neo-section.section-padding-no-top {
    padding-top: 0;
}
.neo-section.section-padding-no-bottom {
    padding-bottom: 0;
}
.neo-section.section-padding-no-top-no-bottom {
    padding-top: 0;
    padding-bottom: 0;
}
.section-heading,
.section-sub-heading {
    text-align: center;
}
.section-heading {
    margin-top: 0;
    margin-bottom: $gutter-standard;
}
.section-sub-heading {
    margin-bottom: $section-padding-small;
    @media #{$large-up} {
        margin-bottom: $section-padding-medium;
    }
}

.section-bg-white {
    background-color: $bg-white-color;
    color: $font-color;
    a:not(.button) {
        color: $link-color;
        &:hover, &:focus {
            color: $link-color-hover;
        }
    }
}
.section-bg-light {
    background-color: $bg-light-color;
    color: $font-color-on-light-bg;
    a:not(.button) {
        color: $link-color-on-light-bg;
        &:hover, &:focus {
            color: $link-color-hover-on-light-bg;
        }
    }
}
.section-bg-dark {
    background-color: $bg-dark-color;
    color: $font-color-on-dark-bg;
    a:not(.button) {
        color: $link-color-on-dark-bg;
        &:hover, &:focus {
            color: $link-color-hover-on-dark-bg;
        }
    }
    .table-hover>tbody>tr:hover, .table-striped>tbody>tr:nth-of-type(odd) {
        color: $bg-dark-color;
    }
    .file-attachment a {
        color: $bg-dark-color;
        &:hover {
            color: darken($bg-dark-color, 20%);
        }
    }
}

.matrix-block-inside-section:not(.matrix-block-dividingLine):not(:last-child) {
    margin-bottom: $section-padding-small;
}

// .matrix-block-dividingLine {
//     @include General-Section-Margin;
// }

.video-iframe-wrapper {
    // max-width: 600px;
    width: 100%;
    // margin: 0 auto;
}
.aspect-ratio {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 57%;
}
.aspect-ratio iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; top: 0;
}
.matrix-block-wrapper.matrix-block-inside-section.matrix-block-wrapper-video {
    margin-top: $section-padding-small;
    margin-bottom: $section-padding-small;
    @media #{$large-up} {
        margin-top: $section-padding-medium;
        margin-bottom: $section-padding-medium;
    }
}
.matrix-block-video {
    .video-full-bg-heading {
        margin-bottom: 15px;
        max-width: 600px;
        width: 100%;
    }
}
.video-iframe-vimeo {
    overflow-x: hidden;
    iframe {
        // margin-left: -32px;
        width: 100%;
        // @media #{$large-up}{
        //     width: 718px;
        // }
    }
}

/** images slider */
.image-slider-wrap {
    position: absolute;
    visibility: hidden;
}
.image-slider-wrap .slider-arrow {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    opacity: 0.6;
}
.image-slider-wrap .slider-arrow:hover {
    opacity: 1;
}
.image-slider-wrap .slider-arrow img {
    width: 48px;
    height: auto;
}
.image-slider-wrap .arrow-previous {
    left: 0;
}
.image-slider-wrap .arrow-next {
    right: 0;
}
/** to fix non-adaptive height styling issue */
.image-slider-wrap .slick-slide {
    overflow: hidden;
}

// .file-attachment.alert {
//     background-color: #ffffff;
//     border-color: #dedede;
//     color: #7d7d7d;
// }
// .file-attachment .glyphicon {
//     font-size: 115%;
//     top: 4px;
//     margin-right: 5px;
// }
.file-attachment {
    .file-attachment-link {
        display: inline-block;
        background-color: $file-attachment-linkbg;
        padding: 13px 48px 13px 27px;
        position: relative;
        border-radius: 4px;
        &:hover, &:focus {
            text-decoration: none;
            .file-attachment-icon {
                background-color: darken($file-attachment-linkbg, 15%);
            }
        }
        .file-attachment-text {
            padding-right: 27px;
            display: inline-block;
            @include H4-Left-white;
            .file-attachment-file-info {
                margin-left: 5px;
                font-size: 14px;
            }
        }
        .file-attachment-icon {
            transition: all 0.4s;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $file-attachment-linkiconbg;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 48px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            img {
                
            }
        }
    }
}

.rich-text {
    & > *:last-child {
        margin-bottom: 0;
    }
}
.rich-text img {
    max-width: 100%;
    height: auto !important;
    margin-top: 16px;
    margin-bottom: 32px;
}

.dividing-line {
    margin-left: 15px;
    margin-right: 15px;
}
.neo-section .dividing-line {
    margin-left: 0;
    margin-right: 0;
}

.circle-text-column-item {
    text-align: center;
    .img-circle {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }
}

.highlighted-content {
    padding: 24px;
    background-color: $color-verylightgrey;
    & > * {
        margin-bottom: 0;
    }
    @media #{$large-up} {
        padding: 32px;
    }
}
.highlighted-content, .columns__text__content {
    blockquote {
        padding: 0;
        & + blockquote {
            margin-top: 0;
            @media #{$large-up} {
                margin-top: 0;
            }
        }
    }
}