@mixin General-Section-Padding {
	padding-top: 56px;
	padding-bottom: 56px;
	@media screen and (min-width: 992px) {
		padding-top: 64px;
		padding-bottom: 64px;
	}
}
@mixin General-Section-Margin {
	margin-top: 56px;
	margin-bottom: 56px;
	@media screen and (min-width: 992px) {
		margin-top: 64px;
		margin-bottom: 64px;
	}
}
@mixin Section-Subheading {
	@include Body-Intro-Center-M;
	@media screen and (min-width: 992px) {
		font-size: 18px;
		line-height: 24px;
	}
}