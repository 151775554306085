@import "ie";
@import "clearfix";
@import "image-url";
@import "font-face";
@import "backgrounds";
@import "positioning";
@import "grid";
@import "gradient-text";
@import "linear-gradient";
@import "appearance";
@import "fonts-healthecare";
@import "healthecare-custom";

//Utilities
@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@mixin visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@mixin reset-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

@mixin font-smoothing($value: on) {
	@if $value == on {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	@else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

@mixin hide-text {
	text-indent: 200%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin word-wrap {
	word-wrap: break-word;
}

@mixin no-tap-highlight {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
}
