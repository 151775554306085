#section-breadcrumb {
	background: linear-gradient(92deg, #54534a, #2b2b22);
	.breadcrumb {
		background: none;
		font-size: 14px;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 0;
		.breadcrumb-item {
			color: #fff;
			&.active {
				font-weight: bold;
			}
			&::before {
				display: none;
			}
			a {
				color: #fff;
				&:hover, &:focus {
					color: $link-on-dark-hover;
					text-decoration: none;
				}
			}
			.icon-breadcrumb-home {
				vertical-align: -1px;
				margin-right: 5px;
			}
			.icon-breadcrumb-arrow {
				vertical-align: 1px;
				margin: 0 13px;
			}
		}
	}

	@media #{$bs-mobile} {
		display: none;
	}
}