.contact-details-entry {
	&:not(:last-child) {
		margin-bottom: 12px;
	}
	.contact-details-icon, .contact-details-span {
		vertical-align: middle;
	}
	.contact-details-span strong {
		margin-right: 3px;
	}
}
.contact-details-phone,
.contact-details-fax,
.contact-details-email {
	display: flex;
	align-items: flex-start;
	.contact-details-span {
		display: flex;
		strong {
			flex: 0 0 auto;
			margin-right: 5px;
		}
	}
}
.contact-details-icon {
	opacity: 0.7;
	margin-right: 9px;
	display: inline-block;
}
.contact-details-text {
	p {
		margin-bottom: 0;
	}
}
.contact-details-email {
	word-break: break-all;
}
@media #{$bs-mobile} {
	.contact-details-dt {
		margin-bottom: 8px;
	}
}