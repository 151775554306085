/** Box page global settings */
.box-page-content-wrapper {
  /*background: #3d3d3b url(/src/images/tyi_bg.jpg) no-repeat center center;*/
  /*background: #3d3d3b url(/src/img/box_page_bg.jpg) no-repeat center center;*/
  background-color: #3d3d3b;
  background-size: cover;
  padding: 300px 15px;
  position: relative;
  /*margin-top: -61px;
  margin-bottom: -90px;*/
}

.box-page-bg-overlay {
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.alert-box-page-wrapper {
  padding-top: 300px;
  padding-bottom: 300px;
}

.box-wrapper {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  // max-width: 600px;
  // width: 100%;
  // padding: 30px;
  background-color: #ffffff;
  -webkit-box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.5);
}

.box-wrapper .box-heading {
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 1.5;
}
.box-wrapper .box-subheading {
  text-align: center;
  line-height: 1.5;
}

.alert-box-wrapper {
  text-align: center;
}

.alert-box-wrapper .box-heading {
  margin-bottom: 0;
}

.alert-box-wrapper .alert-box-text {
  margin-top: 30px;
}

.alert-box-wrapper .box-content-button {
  margin-top: 20px;
}

@media (max-width: 1500px) and (min-width: 992px) {
  .box-page-content-wrapper {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .box-page-content-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .alert-box-page-wrapper {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

@media (max-width: 767px) {
  .box-page-content-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .alert-box-page-wrapper {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.compact-page-center-element-wrapper {
  text-align: center;
}

.errors {
  margin-top: 15px;
  color: #d44950;
  background-color: #f2dede;
  padding: 12px;
  list-style: none;
}
.forgot-password-link-wrapper {
   color: #ddd;
   text-decoration: underline;
   /*margin-top: 8px;*/
   margin-bottom: 15px;
}

.compact-page-btn {
  border: 2px solid black;
    background: white;
    color: black;
    font-weight: 700;
    padding: 13px 35px;
    border-radius: 5px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    text-decoration: none;
}
.compact-page-btn:hover, .compact-page-btn:focus {
    background: black;
    color: white;
    text-decoration: none;
    outline: 0;
}
#email-sign-in-form-wrapper {
  max-width: 264px;
  width: 100%;
  margin: 0 auto;
  // .compact-page-center-element-wrapper {
  //   text-align: left;
  // }
}
.login-screen-sub-functions-wrapper {
  margin-bottom: 24px;
}

/** Healthe Care custom */
.box-page-content-wrapper {
  padding: 0;
  background-color: transparent;
}
.box-wrapper {
  @extend .layout-content;
  box-shadow: none;
  text-align: left;
  .box-heading {
    text-align: left;
    &.box-heading-center {
      text-align: center;
    }
  }
}
.box-page-bg-overlay {
  display: none;
}