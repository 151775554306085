/*
 * FONT: Lato and HouschkaPro
 */

@include font-face(HouschkaPro, "fonts/HouschkaPro-Light", $weight: 400, $file-formats: woff woff2); //houschkaPro- light
@include font-face(HouschkaPro, "fonts/HouschkaPro-Medium", $weight: 500, $file-formats: woff woff2); //houschkaPro- medium
@include font-face(HouschkaPro, "fonts/HouschkaPro-DemiBold", $weight: 600, $file-formats: woff woff2); //houschkaPro- demi bold
@include font-face(HouschkaPro, "fonts/HouschkaPro-Bold", $weight: 700, $file-formats: woff woff2); //houschkaPro- bold
@include font-face(HouschkaPro, "fonts/HouschkaPro-ExtraBold", $weight: 800, $file-formats: woff woff2); //houschkaPro- extrabold

// @include font-face(Lato, "fonts/Lato-Regular", $weight: 400, $file-formats: woff woff2); //Lato - regular - body text
// @include font-face(Lato, "fonts/Lato-Black", $weight: 500, $file-formats: woff woff2); //Lato - Black - buttons
// @include font-face(Lato, "fonts/Lato-Bold", $weight: 700, $file-formats: woff woff2); //Lato - Bold - Main Menu items