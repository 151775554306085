//Theme Base Colour
$theme-color: 'blue' !default;

//base colours
$color-black:					#000000;
$color-white:					#ffffff;
$color-darkgrey: 				#222222;
$color-lightgrey: 				#eee;
$color-anothergrey:				#ced4da; //bs input border

$color-midgrey: 				#717067; //body text colour (brownishgrey)
$color-greyish-brown:           #3d3935; //greyishbrown
$color-palebeige:				#f2f2f2;
$color-green:					#8cc63f;
$color-yellow: 					#f9ed33;
$color-verylightgrey:			#fafafa !default;
$color-almostwhitegrey:			#dcdcdc !default;

$color-emergency-red:           #bf0202;
$color-grey-alt:                #2d2d24;

//set these colours in themes/themename/settings.scss
$color-primary: $color-black !default; 
$color-secondary: $color-darkgrey !default;
$color-tertiary: $color-midgrey !default;
$color-switch: $color-white !default;

//typography - font, buttons and links
$font-color: $color-midgrey !default;
$font-color-on-light-bg: $font-color !default;
$font-color-on-dark-bg: $color-white !default;

$link-color: #337ab7 !default;
$link-color-hover: darken($link-color, 20%) !default;
$link-color-on-light-bg: $link-color !default;
$link-color-hover-on-light-bg: $link-color-hover !default;
$link-color-on-dark-bg: #ccc !default;
$link-color-hover-on-dark-bg: #fff !default;
$color-hr: $color-almostwhitegrey !default;
$color-error: $color-emergency-red;
//backgrounds
$bg-white-color: $color-white;
$bg-light-color: $color-palebeige;
$bg-dark-color: $color-darkgrey;
$bg-verylight-color: $color-verylightgrey !default;
$color-pagination-bg: $color-primary; 	

//banners
$default-banner-bg-color: $color-black;
$default-banner-text-color: $color-white;

$selection-color: $color-black !default;
$selection-text-color: $color-yellow !default; 

$highlight-color: $color-black !default;
$highlight-text-color: $font-color !default;

$gradient-hr-bg: linear-gradient(to right, $color-verylightgrey, $color-almostwhitegrey) !default;

//sidebar
$color-sidebarlink: $color-white !default;
$color-sidebarlinkactive: $color-white !default;
$color-sidebarlinkactive-bg: $color-tertiary !default;
$opacity-sidebar-link-primary: 0.8 !default;
$opacity-sidebar-link-secondary: 0.6 !default;
$color-mobile-sidebar-toggle-color: $color-sidebarlink !default;
$color-mobile-sidebar-toggle-color-hover: darken($color-mobile-sidebar-toggle-color, 20%) !default;

//Mobile Menu
//toggler BG 
$mobile-menu-toggler-bg: $color-primary !default;
$mobile-menu-toggler-bg-expanded: $mobile-menu-toggler-bg !default;
$mobile-menu-toggler-bg-hover: darken($color-search-bar, 10%) !default;
//lvl1
$mobile-menu-lvl1-bg: $color-tertiary !default;
$mobile-menu-lvl1-bg-active: $color-tertiary !default;
//lvl2
$mobile-menu-item-lvl2: $color-brownish-grey !default;
//lvl3
$mobile-menu-item-lvl3: $color-brownish-grey !default;
$mobile-menu-dropdown-lvl3-bg: $color-lightgrey !default;

//Mobile Subnav (this is the sidebar on desktop)
$color-mobile-subnavlink: $color-greyish-brown !default;
$mobile-subnav-defaultbg: $color-white !default;
$mobile-subnav-activebg: $color-white !default;
$mobile-subnav-dropdown-bg: $mobile-menu-lvl1-bg-active !default;
$mobile-subnav-dropdown-bg-expanded: $mobile-subnav-dropdown-bg !default;

//block specific
//tiles
$color-tile-titlebg: $color-primary !default;
$file-attachment-linkbg: $color-primary !default;
$file-attachment-linkiconbg: $link-color-hover !default;

//Specialist Page 
$color-specialist-page-circle-bg: $color-tertiary !default;

//footer
$link-on-dark-hover: $color-primary !default;
$footer-social-link-hover: $color-primary !default;

//Desktop Menu
$desktop-menu-activeandhover: $color-primary !default;
$desktop-menu-dropdown-divider: $color-lightgrey !default;
$desktop-menu-lvl2-3-itembg: $color-verylightgrey !default;
$desktop-menu-lvl1-hover: $color-primary !default;

$selectize-filter-bg: $color-secondary !default;
$selectize-filter-item-active: $color-primary !default;

//Header
$color-search-bar: $color-secondary !default;
