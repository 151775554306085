// header {
// 	position: relative;
// 	z-index: 99999;
// }

//TOP STRIP
.top-strip {
	@include linear-gradient(90deg, $gradient-start 0%, $gradient-end 100%);	
	@include clearfix;
	font-family: $heading-font-family;
	.container {
		position: relative;
		> div {
			display: inline-block;
		}
	}

	@media #{$bs-mobile} {
		.container {
			padding: 0;
		}
		.top-strip__extra {
			position: static;
		}
		.top-strip__logo-wrap {
			padding-left: 15px;
			.logo--hc {
				width: 122px;
				height: auto;
			}
		}
		.top-strip__extra__utility {
			margin-right: 48px;
			li {
				margin-right: 14px;
			}
		}
		.top-strip__extra__search {
			width: auto;
			left: 0;
		}
	}
	@media #{$extra-small-to-small} {
		.top-strip__extra__utility {
			margin-right: 63px;
		}
	}
}

@media screen and (max-width: 400px) {
	.top-strip__extra__contact {
		font-size: 10px;
		line-height: 12px;
	}
}

.top-strip__logo-wrap {
	img {
		margin-right: 25px;
		@media screen and (max-width: 400px) {
			&:last-child {
				margin-right: 3px;
			}
		}
	}
	a {
		display: flex;
	    justify-content: center;
	    align-items: center;
		text-decoration: none;
		@media #{$small-up} {
			display: inline-block;
		}
	}
	.logo--luyemed {
		height: 33px;
	}
}
.top-strip__extra {
	// float: right;
	text-align: right;
	position: relative;
	> * {
		display: inline-block;
	}
}
.top-strip__extra__social, .top-strip__extra__utility {
	@include reset-list;
	float: left;
	z-index: 1;
	position: relative;
	li {
		float: left;
		margin-right: 32px;
		line-height: 48px;
		height: 48px;
		@media #{$medium-down} {
			margin-right: 65px;
		}
		a {
			color: $color-switch;
			&:hover, &:focus {
				color: $color-switch;
				opacity: 0.7;
				text-decoration: none;
			}
		}
	}
}


.top-strip__extra__social {
	margin-right: 48px;
	li {
		.social-link {
			&:hover, &:focus {
				opacity: 0.7;
				path {
					fill: #fefefe;
				}
			}
		}
		@media #{$medium-up} {
			display: flex;
			align-items: center;
			a {
				display: block;
				picture {
					display: flex;
			align-items: center;
				}
			}
		}
	}
}
.top-strip__extra__search {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 48px;
	// transition: width 0.4s;
	@media #{$extra-small-to-small} {
		right: 15px;
	}
	form {
		display: flex;
		width: 100%;
		justify-content: flex-end;
		button, button:focus, input, input:focus {
			outline: none;
			border-radius: 0;
		}
	}
	.top-strip__search_text {
		height: 48px;
		// line-height: 48px;
		border: 0;
		background-color: $color-search-bar;
		color: #fff;
		flex-grow: 1;
		font-family: $heading-font-family;
		font-weight: 500;
		&::placeholder {
			color: lighten($color-white, 30%);
		}
	}
	.top-strip__search_button {
		appearance: none;
		border: none;
		width: 48px;
		height: 48px;
		background-color: $color-search-bar;
		cursor: pointer;
	}
	.top-strip__search_submit_alt {
		display: none;
		padding: 6px 0;
		background-color: $color-search-bar;
		input[type="submit"] {
			min-width: 0px;
			border-radius: 2px;
			size: 13px;
			font-weight: bold;
			letter-spacing: 0.9px;
			padding: 11px 11px 9px 11px;
		}
	}
	.top-strip__search_toggle {
		border: none;
		width: 48px;
		height: 48px;
		display: inline-flex;
		background-color: $color-search-bar;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		&:focus {
			outline: 0;
		}
	}
	.hidden-collapse {
		display: none;
	}
	.icon-close {
		display: none;
	}

	&.expanded {
		z-index: 2;
		width: 540px;
		max-width: 100%;
		@media #{$extra-small-to-small} {
			padding-left: 15px;
			padding-right: 15px;
		}
		@media #{$extra-small-only} {
			width: 100%;
		}
		.hidden-collapse {
			display: block;
		}
		.top-strip__search_submit_alt {
			@media #{$medium-up} {
				display: block;
			}
		}
		.top-strip__search_toggle {
			.icon-search {
				display: none;
			}
			.icon-close {
				display: inline;
			}
		}
	}
}
.header-main {
	max-width: 100%;
}
.top-strip__extra__phone,
.top-strip__extra__social {
	display: none;
}
@media #{$large-up} {
	.top-strip__extra__phone {
		display: list-item;
	}
	.top-strip__extra__social {
		display: inline-block;
	}
}

