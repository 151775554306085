.mc-signup-form {
	.mc-signup-form__container {
		text-align: center;
		@media #{$medium-up} {
			text-align: left;
		}
		@media #{$bs-mobile} {
			background-image: none !important;
		}
	}
	.mc-signup-form__content {
		padding-top: 48px;
		padding-bottom: 5px;
		@media #{$medium-only} {
			padding-bottom: 32px;
		}
		@media #{$large-up} {
			padding-bottom: 100px;
			&.mc-signup-form__content-no-image {
				padding-bottom: 32px;
			}
		}
		.mc-signup-form__intro {
			@include Section-Subheading;
			margin-bottom: 32px;
			& > *:last-child {
				margin-bottom: 0;
			}
			@media #{$medium-up} {
				text-align: left;
			}
		}
	}
	form {
		margin-top: 24px;
	}
	.form-group {
		margin-bottom: 16px;
		input[type="text"].form-control, input[type="email"].form-control {
			padding: 16px;
			font-size: 13px;
			font-weight: bold;
			color: $color-greyish-brown;
			letter-spacing: 0.9px;
			line-height: 16px;
		}
		input {
			width: 100%;
		}
		#mc-embedded-subscribe {
			min-width: 0px;
			padding: 17px 32px;
		}
	}
}
.mc-signup-form__image-container {
	position: relative;
	@media #{$bs-mobile} {
		height: 100vw;
		max-height: 360px;
	}
	.mc-signup-form__image-canvas {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 15px;
		right: 15px;
		background-repeat: no-repeat;
	}
}
.mc-signup-form__mobile-image {
	text-align: center;
	img {
		max-width: 80%;
		max-height: 360px;
	}
	@media #{$medium-up} {
		display: none;
	}
}
