.yump-carousel-outer {
	padding: 0 60px;

	/** Hide before slick initialized */
	position: absolute;
	visibility: hidden;
}
.yump-carousel-item {
	padding: 0 8px;
}
.yump-carousel-item:focus,
.yump-carousel-cell:focus {
	outline: 0;
}
.yump-carousel-cell-multi-items .yump-carousel-item:not(:last-child) {
	margin-bottom: 15px;
}

.yump-carousel-item-img-container {
	height: 80px;
}
.yump-carousel-item-img-container img {
	max-height: 100%;
	max-width: 100%;
	margin: 0 auto;
}
.ie .yump-carousel-item-img-container {
	display: block;
	text-align: center;
}
.ie .yump-carousel-item-img-container img {
	position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.slick-arrow {
    opacity: 0.8;
    cursor: pointer;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);

	appearance: none !important;
}
.slick-arrow:hover {
  opacity: 1;
}
.slick-arrow:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #09F;
}
.slick-arrow:active {
  opacity: 1;
}
.slick-prev {
	left: 0;
}
.slick-next {
	right: 0;
}

.yump-carousel-item p {
	margin-bottom: 0px;
	line-height: 17px;
}
.yump-carousel-item .yump-carousel-item-text-heading {
	color: #959595;
}
.yump-carousel-item .yump-carousel-item-text-subheading {
	color: #dcdcdc;
	margin-top: 5px;
}
.yump-carousel-item-text-container {
	margin-top: 10px;
	text-align: center;
}
