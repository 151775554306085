.stats-counter {
  padding-top: calc(8*#{$spacing-unit});
  padding-bottom: calc(8*#{$spacing-unit});
  color: #555555;
  position: relative;
  @media #{$medium-up}{
    text-align: center;  
  }
  .stats-counter-subheading {
    @include Section-Subheading;
    margin-bottom: 48px;
    color: #fff;
  }
}
.stats-counter-headings {
  @media #{$bs-mobile} {
    text-align: center;
  }
}
.stats-counter__dark-overlay {
    position: absolute;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: url(../img/png/others/counter-circles.png);
    background-position: bottom right;
    background-repeat: no-repeat;
}

.counter-info-wrap {
  @media #{$bs-mobile}{ 
    float: left;
  }
}

.stats-counter-item {
  @media #{$bs-mobile}{
    margin-bottom: calc(6*#{$spacing-unit});
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    figure {
      display: inline-block;
      // float: left;
      margin-right: 16px;
    }
  }
  .stats-counter-number {
    font-size: 50px;
    display: block;
    line-height: 50px;
    color: $color-stats;
    font-family: $heading-font-family;
    font-weight: 500;
    letter-spacing: -2px;
    @media #{$bs-mobile} {
      line-height: 60px;
    }
  }
  svg {
    width: 72px;
    height: 72px;
    //need last child as sometimes the icon has a circle in it
    circle:last-child {
      stroke: $color-stats;
    }
  }
  .stats-counter-text {
    margin-top: $spacing-unit;
    display: inline-block;
    @include Other-Counters;
    @media #{$medium-up} {
      max-width: 170px;
      text-align: center;
    }
    @media #{$bs-mobile} {
      margin-top: 3px;
    }
  }
}
.section-bg-dark .stats-counter {
  color: $font-color-on-dark-bg;
}
.stats-counter-heading, .stats-counter-subheading {
  color: #fff;
}
.stats-counter {
  background: $stat-counter-bg-image;
  background-size: cover;
  background-position: $stat-counter-bg-position-desktop;
}