.twib__summary {
	text-align: center;
	max-width: 750px;
	margin: 0 auto;
	margin-top: calc(7*#{$spacing-unit});
	margin-bottom: calc(4*#{$spacing-unit});
	@media #{$large-up} {
		margin-top: calc(8*#{$spacing-unit});
	}
	p {
		@include Section-Subheading;
	}
	.container > *:last-child {
		margin-bottom: 0;
	}
}
.twib__columns {
	position: relative;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
	
	@media #{$medium-up} {
		.twib__columns__overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			.twib__columns__image {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 50%;
			}
		}
	
	}
}
@media #{$medium-up} {
	.twib__columns.twib-image-align-right {
		.twib__columns__overlay {
			.twib__columns__image {
				right: 0;
				left: auto;
			}
		}
	}
}

.twib__columns__image {
	@media #{$bs-mobile}{
		height: calc(100vw*9/16);
	}
}
.twib__content {
	background: $bg-verylight-color;
}
.twib__columns__text {
	padding-top: calc(6*#{$spacing-unit});
	padding-bottom: calc(6*#{$spacing-unit});
	@media #{$medium-up} {
		min-height: 375px;
		padding-left: calc(4*#{$spacing-unit});
		display: flex;
		align-items: center;
	}
	@media #{$large-up} {
		padding-top: calc(8*#{$spacing-unit});
		padding-bottom: calc(8*#{$spacing-unit});
		padding-left: calc(7*#{$spacing-unit});
	}
	
	@media #{$bs-mobile}{
		p {
			text-align: center;
			width: 100%;
		}
		&.order-first { //reverses on mobile..
			order: 1;
		}
	}
	// blockquote {
	// 	+ p {
	// 		margin-bottom: 0;
	// 	}
	// }
	.button {
		margin-top: calc(4*#{$spacing-unit});
	}
}

.twib-image-align-right {
	.twib__columns__text {
		@media #{$medium-up} {
			padding-left: 15px;
			padding-right: calc(4*#{$spacing-unit});
		}
		@media #{$large-up} {
			padding-left: 15px;
			padding-right: calc(7*#{$spacing-unit});
		}
	}
}
.columns__text__content-wrap {
	@media #{$bs-mobile} {
		text-align: center;
	}
	// display: flex;
	// align-items: left;
	// justify-content: center;
	// flex-direction: column;
	// height: 100%;
	// @media #{$extra-large-up}{
	// 	max-width: 495px;	
	// }
	.columns__text__content {
		& > *:last-child {
			margin-bottom: 0;
		}
	}
}
