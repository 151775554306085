// Really we should be loading bootstrap in with Node via Gulp and altering a BS form control 
// to match and then just including (@include) form-control on an input
label, .cognito label {
	font-weight: bold;
    line-height: 22px;
    margin-bottom: 8px;
    display: inline-block;
}
input[type="text"], select, textarea {
    line-height: 1.5;
    background-color: $color-white;
    background-clip: padding-box;
    border: 1px solid $color-anothergrey;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	border-radius: 4px;
	font-size: 14px;
	padding: 0 16px;
	display: block;
}
input[type="text"], select {
    width: 100%;
	height: 48px;
}
input[type="radio"], input[type="checkbox"] {
	margin-right: 5px;
}
textarea {
	padding: 10px 16px;
}
legend {
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 0.2em !important;
}

/** 
Cognito Form Overrides 
**/
// Hack this out so it lines up with our page width
.cognito {
	margin-left: -7px !important;
    margin-right: -7px !important;
    position: relative;
    min-height: 600px;
}

.cognito {
	&:before {
		background-image: url(../img/png/others/loading-spinner.gif);
	    background-repeat: no-repeat;
	    content: ' ';
	    width: 100%;
	    height: 600px;
	    display: -webkit-box;
	    position: absolute;
	    z-index: 1000;
	    background-color: white;
	    background-size: 100px;
	}
	&.ready {
		&:before {
			display: none;
		}
	}
}
// Datepicker gets the positioning calulation wrong when the sticky header is in place, this corrects it
.cognito-datepicker.cognito-dropdown-menu.cognito-datepicker-orient-top {
	margin-top: 40px;
}
.cognito .c-forms-heading .c-forms-form-title {
	display: none;
}
.cognito legend {
	font-size: inherit;
}
.cognito .c-editor .c-choice-option label, .cognito .c-editor label.c-yesno-radio, .cognito .c-editor.c-yesno-checkbox label {
	margin-bottom: 0;
	display: flex;
	align-items: center;
	padding-left: 0;
	text-indent: 0;
	font-size: 15px;
	font-weight: 400;
}
.cognito .c-helptext {
	font-size: 14px;
}
// Validation
.cognito .c-forms-form .c-validation, .cognito .c-forms-form .c-error:after, .cognito .c-forms-form .c-error .toggle-on:after, .cognito .c-forms-form .c-validation-icon {
	background-color: $color-error !important;
	padding: 0.7em 16px;
}

