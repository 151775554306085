.hc-filter {
	margin-bottom: calc(48px - 1rem);
	label {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 11px;
	}
	input[type="text"] {
		font-size: 14px;
		line-height: 17px;
		padding: 16px;
	}
	.form-control {
		height: 48px !important;
	}
	.small-print {
		display: block;
		margin-top: 9px;
		line-height: 15px;
		margin-top: 6px;
		display: inline-block;
	}
}