.search-results-page {
	.page-title {
		margin-bottom: 0;
	}
	.page-headings {
		margin-bottom: 9px;
		@media #{$medium-up} {
			margin-bottom: 37px;
		}
	}
}
.search-results-section__results {
	.results-count {
		@include Section-Subheading;
		text-align: left;
	}
	form button {
		background-color: $color-secondary;
		// margin-left: 5px; // Jay
	}
}
.search-results-list {
	@include reset-list;
	margin-top: 25px;
	li {
		margin-bottom: 22px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.search-result-entry-title {
		@include H4-Left-Blue-M;
		margin-bottom: 8px;
		color: $color-secondary;
		a {
			color: $color-secondary;
		}
	}
	.excerpt {
		h1,h2,h3,h4,h5 {
			font-size: 18px;
			color: $font-color;  
			margin-bottom: 5px;
			margin-top: 5px;
			// font: {
			// 	family: $alt-heading-font-family;
			// 	weight: $body-font-weight-normal;
			// }	
		}
		p {
			margin: 5px 0;
		}
	}
	&.filtering {
		opacity:0.5;
	}
}
.filter-wrap {
	background: $color-verylightgrey;
	padding: 16px;
	.filter-heading {
		color: $font-color;
		font-size: 16px;
		margin-bottom: 7px;
		line-height: 22px;
		font-weight: bold;
	}
	hr {
		margin: 0;
	}
	.checkbox {
		margin-top: 24px;
		position: relative;
		label {
			margin-bottom: 0;
		}
		input {
			margin-right: 10px;
			position: absolute;
			top: 5px;
		}
		span {
			display: block;
			margin-left: 25px;
		}
	}
	p {
		// @include font-size($fine-print-sizes);
	}
}

.search-results-section {
	// margin-bottom: 45px;
}

.searchform-search-page {
	button {
  
	}
}

@media #{$bs-mobile} {
	.filter-wrap {
		margin-bottom: 32px;
	}
}
@media #{$medium-up} {
	.search-results-section__results {
		padding-top: 5px;
	}
}

.search-again-form {
	.search-field {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		@media #{$extra-small-only} {
			width: calc(100% -  44px);
		}
	}
	button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		cursor: pointer;
	}
}