.bottom-specialists-section {
	.bottom-specialists-section-inner {
		@include General-Section-Padding;
	}
	h2 {
		text-align: center;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	.body-intro {
		@include Body-Intro-Center;
		margin-bottom: 32px;
		@media #{$medium-up} {
			margin-bottom: 48px;
		}
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	.specialists-list {
		margin-top: 32px;
		@media #{$medium-up} {
			margin-top: 48px;
		}
	}
}
.specialists-list {
	.no-data-hint {
		text-align: center;
	}
}
.specialist-item {
	.card__image-container {
		width: 96px;
		height: 96px;
		border-radius: 50%;
		position: relative; // fix the safari scale transition overflow visible bug
		z-index: 10; // fix the safari scale transition overflow visible bug
		flex: 0 0 auto;
		margin-right: 16px;
		align-self: flex-start;
		@media #{$medium-up} {
			margin-right: 24px;
		}
		.card__image-container__image {
			width: 96px;
			height: 96px;
		}
	}
	.specialist-item-body {
		padding: 16px 18px;
		display: flex;
		align-items: center;
		.specialist-item-body-specialties {
		 	@include Body-Body-Left;
		 	// font-size: 15px;
		 	// line-height: 21px;
		}
	}
}
.specialty-related-specialists-section {
	border-top: 2px solid $color-hr;
	@media #{$medium-down} {
		border-top: none;
		.bottom-specialists-section-inner {
			border-top: 2px solid $color-hr;
		}
	}
}
.other-specialists-section {
	.bottom-specialists-section-inner {
		border-top: 2px solid $color-hr;
	}
}