.news-list .news-list__entry {
	.card-wrap-link {
		width: auto;
		height: auto;
	}
	.card-block {
		.card-title {
			background-color: transparent;
			.card-title-link {
				color: $color-secondary;
				font-weight: bold;
				text-align: left;
				margin-top: calc(3*#{$spacing-unit});
				height: auto;
				padding: 0 16px;
				min-height: auto;
				&:hover, &:focus {
					color: $color-primary;
				}
			}
		}
		.card-block__text-wrap {
			padding-top: $spacing-unit;
		}
		.news-list__entry__summary {
			margin-bottom: 29px;
		}
		.button {
			min-width: 0px;
			padding: 11px 9px 11px 11px;
			border-radius: 2px;
		}
	}
}
.news-list {
	margin-top: 48px;
}
.load-more-button {
	
}

// latest news hardcoded block styling
.latest-news-section {
	display: none;
	@media #{$medium-up} {
		display: block;
	}

	@include General-Section-Margin;
	.latest-news-header {
		margin-bottom: 44px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h1 {
			margin-bottom: 0;
		}
		.button, .button:hover, .button:focus {
			color: #fff;
		}
	}
}
.latest-news-list {
	@media #{$large-up} {
		.tile-item:nth-child(4) {
			display: none;
		}
	}
}