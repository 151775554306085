$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;
$hamburger-layer-width: 14.5px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 2px;
$hamburger-types: (squeeze);
$hamburger-layer-color: #fff;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;

@import "hamburgers"